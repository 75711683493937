import { useAuth } from "../context/AuthContext"


const ConnectWallet = () => {
    const { setWeb3type } = useAuth()

    return (
        <div>
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{
                paddingTop: "150px"
            }}>
                <div className="modal-content" style={{
                    backgroundColor: "transparent"
                }}>
                    <div className="modal-body">
                        <div className="account-form-area">
                            <h3 className="title">Connect Wallet</h3>
                            <div className="account-form-wrapper">
                                <div className="form-group text-center mt-2">
                                    <button className="cmn-btn m-1" onClick={() => {
                                        setWeb3type("mtm")
                                    }}>
                                        <img src="assets/logo/metamask.svg" width={200} />
                                    </button>

                                    <button className="cmn-btn m-1" onClick={() => {
                                        setWeb3type("wc")
                                    }}>
                                        <img src="https://walletconnect.com/images/logo.svg" width={200} />
                                    </button>

                                </div>
                                <div className="divider">
                                    <span>Join us on</span>
                                </div>
                                <ul className="social-link-list">
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-google-plus-g"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConnectWallet