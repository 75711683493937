import "./buyticket.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { creepy_game, token } from "../../context/abi";
import { Backdrop, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import Countdown from "react-countdown";

const BuyTicket = () => {
  const { web3 } = useAuth();
  const [contract, setContract] = useState(null);
  const [contractToken, setContractToken] = useState(null);
  const [prize, setPrize] = useState(null);
  const [loading, setLoading] = useState(false);
  const [round, setRound] = useState(null);
  const [roundId, setRoundId] = useState(0);
  const [duration, setDuration] = useState(0);

  const [pool, setPool] = useState(0);

  useEffect(() => {
    const creepy_game_ct = new web3.library.eth.Contract(
      creepy_game.abi,
      creepy_game.contract
    );
    setContract(creepy_game_ct);
    setContractToken(new web3.library.eth.Contract(token.abi, token.contract));
  }, [web3]);

  useEffect(() => {
    if (contract) {
      contract.methods
        .getTicketPrice()
        .call()
        .then((data) => {
          setPrize([data.bnbhalf_, data.tokenhalf_]);
        });
      getRoundInfo();
    }
  }, [contract]);

  const getRoundInfo = async () => {
    const roundidx = await contract.methods.currentRoundId().call();
    setRoundId(Number(roundidx));
    const roundDuration = await contract.methods.roundDuration().call()
    setDuration(Number(roundDuration));
    const currentPool = await contract.methods.getPrizeCurrentRound().call()
    setPool(Number(web3.library.utils.fromWei(currentPool)))
    contract.methods
      .roundInfo(Number(roundidx) - 1)
      .call()
      .then((data) => {
        setRound(data)
      })
  };

  const [listNumbers, setListNumbers] = useState([
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
  ]);

  const [listActive, setListActive] = useState([]);

  const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  const numberCol = (colIndex, frameIndex) => (
    <ul key={`column_${colIndex}`} className="lottery-single__number">
      {numbers.map((num) => (
        <li
          className={
            listActive.includes(`${frameIndex}_${colIndex}_${num}`)
              ? "active"
              : ""
          }
          key={`number_${num}`}
          onClick={() => onPressNum(num, colIndex, frameIndex)}
        >
          {num}
        </li>
      ))}
    </ul>
  );

  const checkListNum = (listActive, frameIndex, colIndex) => {
    for (const item of listActive) {
      if (item[0] === frameIndex && item[2] === colIndex) return true;
    }
    return false;
  };

  const checkNumExistInFrame = (listActive, frameIndex, number) => {
    for (const item of listActive) {
      if (item[0] === frameIndex && item[4] === number) return true;
    }
    return false;
  };

  const onPressNum = (num, colIndex, frameIndex) => {
    const chk = `${frameIndex}_${colIndex}_${num}`;

    if (checkNumExistInFrame(listActive, frameIndex.toString(), num.toString()))
      return;

    if (listActive.includes(chk)) {
      setListActive(listActive.filter((item) => item !== chk));
    } else if (
      checkListNum(listActive, frameIndex.toString(), colIndex.toString())
    ) {
      setListActive([
        ...listActive.filter(
          (item) =>
            item[0] !== frameIndex.toString() || item[2] !== colIndex.toString()
        ),
        chk,
      ]);
    } else {
      setListActive([...listActive, chk]);
    }

    let tempListNumbers = [...listNumbers];
    tempListNumbers[frameIndex][colIndex] = num;
    setListNumbers(tempListNumbers);
  };

  const buyRandomTicket = async (ticketnum) => {
    if (contract) {
      setLoading(true);
      const allowanceNum = Number(
        await contractToken.methods
          .allowance(web3.account, creepy_game.contract)
          .call()
      );
      if (allowanceNum <= Number(prize[1]) * 2) {
        contractToken.methods
          .approve(
            creepy_game.contract,
            web3.library.utils.toWei((1000000000).toString(), "ether")
          )
          .send({
            from: web3.account,
          })
          .then(() => {
            toast("Approval successfull");
            setLoading(false);
          })
          .catch(() => {
            toast("Approval failed");
            setLoading(false);
          });
      } else
        contract.methods
          .buyRandomTicket(Number(ticketnum))
          .send({
            from: web3.account,
            value: prize[0] * ticketnum,
          })
          .then(() => {
            toast("Buy ticket successfull");
            setLoading(false);
          })
          .catch(() => {
            toast("Buy ticket failed");
            setLoading(false);
          });
    }
  };

  const buyScratchTicket = async () => {
    if (contract) {
      setLoading(true);
      const allowanceNum = Number(
        await contractToken.methods
          .allowance(web3.account, creepy_game.contract)
          .call()
      );
      if (allowanceNum < Number(prize[1]) * 2) {
        contractToken.methods
          .approve(
            creepy_game.contract,
            web3.library.utils.toWei((1000000000).toString(), "ether")
          )
          .send({
            from: web3.account,
          })
          .then(() => {
            toast("Approval successfull");
            setLoading(false);
          })
          .catch(() => {
            toast("Approval failed");
            setLoading(false);
          });
      } else
        contract.methods
          .buyScratchTicket(listNumbers[0], listNumbers[1], listNumbers[2])
          .send({
            from: web3.account,
            value: prize[0],
          })
          .then(() => {
            toast("Buy ticket successfull");
            setLoading(false);
          })
          .catch(() => {
            toast("Buy ticket failed");
            setLoading(false);
          });
    }
  };

  return (
    <>
      <div class="inner-hero-section style--two">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <ul class="page-list">
                <li>
                  <Link to={"/"}>Home</Link>
                </li>
                <li class="active">Pick your Number</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <section class="pb-120 mt-minus-50">
        <div className="container">
          <div className="row">
            <Countdown
              key={round ? round.finishedA * 100 : "01"}
              date={
                (round ? round.finishedAt : 0) * 1000 + Number(duration) * 1000
              }
              renderer={({ hours, minutes, seconds, completed }) => {
                if (completed) {
                  // Render a completed state
                  return (
                    <div className="col-lg-12">
                      <div className="lottery-wrapper pick-lottery-package">
                        <h2 className="title">Round #{roundId} ended</h2>
                      </div>
                    </div>
                  );
                } else {
                  // Render a countdown
                  return (
                    <>
                      <div className="col-lg-12">
                        <div className="lottery-wrapper pick-lottery-package">
                          <h2 className="title">Round #{roundId}</h2>
                          <p>POOL REWARD: {pool} BNB</p>
                          <p>
                            <Countdown
                              key={round ? round.finishedAt : "0"}
                              date={
                                (round ? round.finishedAt : 0) * 1000 +
                                Number(duration) * 1000
                              }
                            />
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 mt-50">
                        <div className="lottery-wrapper pick-lottery-package">
                          <h3 className="title">Choose a Quick Pick</h3>
                          <p>
                            Price:{" "}
                            {prize &&
                              web3.library.utils.fromWei(
                                prize[0],
                                "ether"
                              )}{" "}
                            BNB &{" "}
                            {prize &&
                              parseFloat(
                                web3.library.utils.fromWei(prize[1], "ether")
                              ).toFixed(2)}{" "}
                            $CRC (per 1 ticket with 3 numbers)
                          </p>
                          <div className="lottery-package-list mt-2">
                            <a onClick={() => buyRandomTicket(1)}>
                              1 Ticket
                            </a>
                            <a onClick={() => buyRandomTicket(3)}>
                              3 Tickets
                            </a>
                            <a onClick={() => buyRandomTicket(5)}>
                              5 Tickets
                            </a>
                            <a onClick={() => buyRandomTicket(10)}>
                              10 Tickets
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 mt-50">
                        <div className="lottery-wrapper">
                          <h2 className="lottery-wrapper__title">
                            Choose your own Tickets
                          </h2>
                          <p>
                            Price:{" "}
                            {prize &&
                              web3.library.utils.fromWei(
                                prize[0],
                                "ether"
                              )}{" "}
                            BNB &{" "}
                            {prize &&
                              parseFloat(
                                web3.library.utils.fromWei(prize[1], "ether")
                              ).toFixed(2)}{" "}
                            $CRC
                          </p>
                          <div className="row mt-50 mb-none-30">
                            {listNumbers.map((listNum, index) => {
                              return (
                                <div
                                  key={`frame_${index}`}
                                  className="col-lg-4 mb-30"
                                >
                                  <div className="lottery-single">
                                    <div className="lottery-single__header">
                                      <span className="titcket-number">
                                        Number #{index + 1}
                                      </span>
                                      <ul className="lottery-single__selected-number">
                                        {listNum.map((num, ind) => (
                                          <li key={`num_result_${ind}`}>
                                            {num}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                    <div className="lottery-single__body">
                                      {[...Array(5)].map((item, colIndex) =>
                                        numberCol(colIndex, index)
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="lottery-wrapper__btn">
                            <button
                              className="btn-border text-black"
                              onClick={buyScratchTicket}
                            >
                              Buy Ticket
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
              }}
            />
          </div>
        </div>
      </section>
      <Backdrop open={loading}>
        <div className="flex justify-center m-auto flex-col">
          <p className="text-white font-bold w-2/3 m-auto text-center">
            Loading
          </p>
          <CircularProgress className="m-auto text-white" color="inherit" />
        </div>
      </Backdrop>
    </>
  );
};

export default BuyTicket;
