// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";

import Layout from "./components/layout/layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage from "./pages";

import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import { AuthContextProvider } from "./context/AuthContext";
import BuyTicket from "./pages/buyTicket/buyticket";
import NftStaking from "./pages/nftStaking/nftStaking";
import ListBuyTicket from "./pages/listBuyTicket/listBuyTicket";
import MintNft from "./pages/mintNft/mintNft";
import { ToastContainer } from "react-toastify";

function getLibrary(provider) {
  return new Web3(provider);
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <AuthContextProvider>
        <BrowserRouter>
          <Layout>
            <ToastContainer
              className="p-4"
              position="top-left"
              autoClose={4000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Routes>
              <Route path="/">
                <Route path="buyticket" element={<BuyTicket />} />
                <Route path="mytickets" element={<ListBuyTicket />} />
                <Route path="mint" element={<MintNft />} />
                <Route path="staking" element={<NftStaking />} />
                <Route index element={<Homepage />} />
                <Route path="*" element={<Homepage />} />
              </Route>
            </Routes>
          </Layout>
        </BrowserRouter>
      </AuthContextProvider>
    </Web3ReactProvider>
  );
}

export default App;
