import React, { useEffect, useState } from "react"
import Countdown from 'react-countdown';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { creepy_game, token } from "../context/abi";
import { useAuth } from "../context/AuthContext";

const Homepage = () => {

    const { web3 } = useAuth()
    const [contract, setContract] = useState(null)
    const [contractToken, setContractToken] = useState(null)
    const [prize, setPrize] = useState(null)
    const [loading, setLoading] = useState(false)
    const [round, setRound] = useState(null)
    const [roundId, setRoundId] = useState(0)
    const [duration, setDuration] = useState(0)

    const [pool, setPool] = useState(0)

    const [rounds, setRounds] = useState([])

    const [num0, setNum0] = useState(0)
    const [num1, setNum1] = useState(0)
    const [num2, setNum2] = useState(0)
    const [num3, setNum3] = useState(0)
    const [num4, setNum4] = useState(0)
    const [num5, setNum5] = useState(0)

    useEffect(() => {
        const creepy_game_ct = new web3.library.eth.Contract(creepy_game.abi, creepy_game.contract)
        setContract(creepy_game_ct)
        setContractToken(new web3.library.eth.Contract(token.abi, token.contract))
    }, [web3])

    useEffect(() => {
        if (contract) {
            contract.methods.getTicketPrice().call()
                .then((data) => {
                    setPrize([data.bnbhalf_, data.tokenhalf_])
                })
            getRoundInfo()
        }

    }, [contract])

    const getRoundInfo = async () => {
        const roundidx = await contract.methods.currentRoundId().call()
        setRoundId(Number(roundidx))
        setDuration(Number(await contract.methods.roundDuration().call()))
        const currentPool = await contract.methods.getPrizeCurrentRound().call()
        setPool(Number(web3.library.utils.fromWei(currentPool)))
        contract.methods.roundInfo(Number(roundidx) - 1).call()
            .then((data) => {
                setRound(data)
            })
        let allRounds = []

        for (let i = 0; i < Number(roundidx); i++) {
            const abc = await contract.methods.roundInfo(i).call()
            abc.id = i
            allRounds.push(abc)
        }
        console.log(allRounds)
        setRounds(allRounds)
    }

    const checkNumbers = async () => {
        if (isNaN(num0) || isNaN(num1) || isNaN(num2) || isNaN(num3) || isNaN(num4) || isNaN(num5) || round === null) {
            toast("Invalid Numbers")
        } else {
            const num2check = Number(num1 * 10000) + Number(num2 * 1000) + Number(num3 * 100) + Number(num4 * 10) + Number(num5)

            contract.methods.roundInfo(Number(num0)).call()
                .then((data) => {
                    if (data.num5 == num2check || data.num4 == num2check % 10000 || data.num3 == num2check % 1000) {
                        toast("You 're winner")
                    } else {
                        toast("You 're not winner")
                    }
                })
        }
    }



    return (
        <React.Fragment>
            <section className="inner-hero-section style--four">
                {/* <div className="bg-shape"><img src="/assets/images/elements/inner-hero-shape-2.png" alt="image" /></div> */}
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="inner-page-content">
                                <h2 className="title">CREEPY POT!</h2>
                                <p>Get some creepy tickets and win a dream hell life</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mt-minus-150">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="winner-details-wrapper bg_img" style={{
                                backgroundImage: "url(assets/images/elements/layer14.png)"
                            }}>
                                <div className="left">
                                    <img src="/assets/images/favicon.png" alt="image" />
                                </div>
                                <div className="body">
                                    <p className="contest-number">ROUND #{roundId}</p>
                                    <p className="contest-date">
                                        <span>Time remaining :</span> <Countdown key={round ? round.finishedAt * 1000 : "02"} date={(round ? round.finishedAt : 0) * 1000 + Number(duration) * 1000} />
                                        <h4 className="title">POOL {pool} BNB</h4>

                                    </p>
                                    <div className="line"></div>
                                    <h4 className="title">Latest Round</h4>

                                    <ul className="numbers">
                                        {
                                            round && round.num5.padStart(5, "0").split("").map((val) => <li>{val}</li>)
                                        }
                                    </ul>
                                    <div className="btn-grp">
                                        <Link to={"/mytickets"}>
                                            <a className="btn-border">Claim</a>
                                        </Link>
                                    </div>
                                </div>
                                <div className="right">
                                    <img src="/assets/images/victory.png" alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="latest-winner-section position-relative pt-120 pb-120">
                {/* <div className="el-1"><img src="assets/images/elements/w-el-1.png" alt="image" /></div>
                <div className="el-2"><img src="assets/images/elements/w-el-2.png" alt="image" /></div>
                <div className="el-3"><img src="assets/images/elements/w-el-3.png" alt="image" /></div> */}
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
                            <div className="section-header text-center">
                                <h2 className="section-title">CreepyPot History</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
                        <div className="col-lg-12">
                            <div className="tab-content mt-50" id="myTabContent">
                                <div className="tab-pane fade show active" id="dream" role="tabpanel" aria-labelledby="dream-tab">
                                    <div className="row mb-none-30">
                                        <div className="col-lg-4 mb-30" >
                                            <div className="number-check-wrapper">
                                                <h3 className="title">Check My Numbers</h3>
                                                <p>Here's an easy way to find out.</p>
                                                <div className="check-number-form">
                                                    <input type="number" className="form-control mt-30 mb-30" name="check-number1" id="check-number1" placeholder="Enter Round No"
                                                        value={num0} onChange={(e) => setNum0(e.target.value)}
                                                    />
                                                    <div className="number-list-wrapper">
                                                        <p>Enter Your Numbers</p>
                                                        <div className="number-list mt-3 mb-3">
                                                            <input type="text" name="text1" id="text1" placeholder="0" value={num1} onChange={(e) => setNum1(e.target.value)} />
                                                            <input type="text" name="text2" id="text2" placeholder="0" value={num2} onChange={(e) => setNum2(e.target.value)} />
                                                            <input type="text" name="text3" id="text3" placeholder="0" value={num3} onChange={(e) => setNum3(e.target.value)} />
                                                            <input type="text" name="text4" id="text4" placeholder="0" value={num4} onChange={(e) => setNum4(e.target.value)} />
                                                            <input type="text" name="text5" id="text5" placeholder="0" value={num5} onChange={(e) => setNum5(e.target.value)} />
                                                        </div>
                                                        <div className="text-center mt-20">
                                                            <button className="cmn-btn" onClick={checkNumbers}>check</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 mb-30">
                                            {
                                                rounds.sort((a, b) => b.id - a.id).slice(0, 4).map((val) => {

                                                    return (
                                                        <div className="winner-card mb-30" >
                                                            <div className="winner-card__thumb">
                                                                <img src="/assets/images/favicon.png" alt="image" />
                                                            </div>
                                                            <div className="winner-card__content">
                                                                <div className="winner-thumb">
                                                                </div>
                                                                <div className="content-top">
                                                                    <div className="left">
                                                                        <h5>Round #{val.id}</h5>
                                                                    </div>
                                                                    <div className="right">
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div className="content-bottom">
                                                                    <div className="number-list-wrapper">
                                                                        <p>Winning Number:</p>
                                                                        <ul className="number-list mt-2">
                                                                            {
                                                                                val.num5.padStart(5, "0").split("").map(v => <li>{v}</li>)
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Homepage