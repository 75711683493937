import { useAuth } from "../../context/AuthContext"
import { mainnet } from "../../context/config"
import ConnectWallet from "../connectwallet"
import Footer from "./footer"
import Header from "./header"



const Layout = ({ children }) => {
    const { web3, setWeb3type } = useAuth()

    return (
        <div className="page-wrapper">
            <Header web3={web3} setWeb3type={setWeb3type} />
            {
                web3 && web3.active && web3.chainId === mainnet.chain_num ?
                    children : <ConnectWallet />
            }
            <Footer />
        </div>
    )
}

const Linhtinh = () => {
    return (
        <>
            <div className="modal fade" id="loginModal" tabindex="1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="account-form-area">
                                <button type="button" className="close-btn" data-dismiss="modal" aria-label="Close"><i className="las la-times"></i></button>
                                <h3 className="title">Welcome Back</h3>
                                <div className="account-form-wrapper">
                                    <form>
                                        <div className="form-group">
                                            <label>Email <sup>*</sup></label>
                                            <input type="email" name="login_name" id="login_name" placeholder="Enter your Email" />
                                        </div>
                                        <div className="form-group">
                                            <label>password <sup>*</sup></label>
                                            <input type="password" name="login_pass" id="login_pass" placeholder="password" />
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-between mt-2">
                                            <div className="custom-checkbox">
                                                <input type="checkbox" name="id-1" id="id-1" checked />
                                                <label for="id-1">Remember Password</label>
                                                <span className="checkbox"></span>
                                            </div>
                                            <a href="#" className="link">Forgot Password?</a>
                                        </div>
                                        <div className="form-group text-center mt-5">
                                            <button className="cmn-btn">log in</button>
                                        </div>
                                    </form>
                                    <p className="text-center mt-4">Don’t have an account? <a href="#" data-toggle="modal" data-target="#signupModal"> Sign Up Now</a></p>
                                    <div className="divider">
                                        <span>or</span>
                                    </div>
                                    <ul className="social-link-list">
                                        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fab fa-google-plus-g"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="signupModal" tabindex="1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="account-form-area">
                                <button type="button" className="close-btn" data-dismiss="modal" aria-label="Close"><i className="las la-times"></i></button>
                                <h3 className="title">Open Free Account</h3>
                                <div className="account-form-wrapper">
                                    <form>
                                        <div className="form-group">
                                            <label>Email <sup>*</sup></label>
                                            <input type="email" name="signup_name" id="signup_name" placeholder="Enter your Email" />
                                        </div>
                                        <div className="form-group">
                                            <label>password <sup>*</sup></label>
                                            <input type="password" name="signup_pass" id="signup_pass" placeholder="password" />
                                        </div>
                                        <div className="form-group">
                                            <label>confirm password <sup>*</sup></label>
                                            <input type="password" name="signup_re-pass" id="signup_re-pass" placeholder="Confirm Password" />
                                        </div>
                                        <div className="d-flex flex-wrap mt-2">
                                            <div className="custom-checkbox">
                                                <input type="checkbox" name="id-2" id="id-2" checked />
                                                <label for="id-2">I agree to the</label>
                                                <span className="checkbox"></span>
                                            </div>
                                            <a href="#" className="link ml-1">Terms, Privacy Policy and Fees</a>
                                        </div>
                                        <div className="form-group text-center mt-5">
                                            <button className="cmn-btn">log in</button>
                                        </div>
                                    </form>
                                    <p className="text-center mt-4"> Already have an account? <a href="#" data-target="#loginModal">Login</a></p>
                                    <div className="divider">
                                        <span>or</span>
                                    </div>
                                    <ul className="social-link-list">
                                        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fab fa-google-plus-g"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Layout