import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { creep_nft, token } from "../../context/abi";
import { useAuth } from "../../context/AuthContext";
import "./mintNft.css";

const MintNft = () => {

  const { web3 } = useAuth();
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState(null);
  const [contractToken, setContractToken] = useState(null);

  const [quantity, setQuantity] = useState(1);
  const [priceBNB, setPriceBNB] = useState(0.4)
  const [priceTOKEN, setPriceTOKEN] = useState(0)
  const [payType, setPayType] = useState(0)

  useEffect(() => {
    const creepy_nft_ct = new web3.library.eth.Contract(
      creep_nft.abi,
      creep_nft.contract
    );
    setContract(creepy_nft_ct);
    setContractToken(new web3.library.eth.Contract(token.abi, token.contract));
  }, [web3]);

  useEffect(() => {
    if (contract) {
      updatePayType(payType)
    }
  }, [contract, contractToken, loading]);

  useEffect(() => {
    updateQuantity(quantity)
  }, [quantity])


  const updatePayType = async (ptype) => {
    setPayType(Number(ptype))
    const getPrice = await contract.methods.getPrice(quantity, ptype).call()
    setPriceBNB(Number(web3.library.utils.fromWei(getPrice.bnb_)))
    setPriceTOKEN(Number(web3.library.utils.fromWei(getPrice.token_)).toFixed(3))
  }

  const updateQuantity = async (q) => {
    const getPrice = await contract.methods.getPrice(q, payType).call()
    setPriceBNB(Number(web3.library.utils.fromWei(getPrice.bnb_)))
    setPriceTOKEN(Number(web3.library.utils.fromWei(getPrice.token_)).toFixed(3))

  }

  const decrementCount = () => {
    if (quantity > 0)
      setQuantity(quantity - 1);
  };

  const incrementCount = () => {
    if (quantity < 3)
      setQuantity(quantity + 1);
  };

  const mintNft = async () => {
    const totalSup = await contract.methods.totalSupply().call()
    if (Number(totalSup) < 1600) {
      setLoading(true)
      const getPrice = await contract.methods.getPrice(quantity, payType).call()
      if (Number(getPrice.token_) > 0) {
        const allowanceNum = Number(await contractToken.methods.allowance(web3.account, creep_nft.contract).call());
        if (allowanceNum < Number(getPrice.token_)) {
          contractToken.methods
            .approve(
              creep_nft.contract,
              web3.library.utils.toWei((1000000000).toString(), "ether")
            )
            .send({
              from: web3.account,
            })
            .then(() => {
              toast("Approval successfull");
              setLoading(false);
            })
            .catch(() => {
              toast("Approval failed");
              setLoading(false);
            });
        } else {
          contract.methods.minNFT(payType, quantity).send({
            from: web3.account,
            value: Number(getPrice.bnb_),
          }).then(() => {
            toast("Mint NFT successfull");
            setLoading(false);
          })
            .catch(() => {
              toast("Mint NFT failed");
              setLoading(false);
            });
        }
      } else {
        contract.methods.minNFT(payType, quantity).send({
          from: web3.account,
          value: Number(getPrice.bnb_),
        }).then(() => {
          toast("Mint NFT successfull");
          setLoading(false);
        })
          .catch(() => {
            toast("Mint NFT failed");
            setLoading(false);
          });
      }
    } else {
      toast("Sold out !!")
    }


  }

  return (
    <div className="inner-hero-section style--two">
      <div className="pb-120">
        <div className="container">
          <section className="">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="affiliate-single-wrapper pt-30 pb-30">
                    <div className="section-header mb-0">
                      <span className="section-sub-title">Mint CreepyBox</span>
                      <hr className="underline" />
                      <div className="pandobox-container">
                        <img
                          src="assets/images/box.png"
                          alt="image"
                          className="pandobox-img"
                        />
                      </div>
                      <div className="container py-4">
                        <div className="row">
                          <div className="col-sm-3">
                            <p>Quantity</p>
                            <div className="input-group">
                              <span className="input-group-prepend">
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary btn-number"
                                  disabled={quantity <= 1}
                                  data-type="minus"
                                  data-field="quant[1]"
                                  onClick={decrementCount}
                                >
                                  <span className="fa fa-minus"></span>
                                </button>
                              </span>
                              <input
                                type="text"
                                name="quant[1]"
                                className="form-control input-number"
                                value={quantity}
                                min="1"
                              />
                              <span className="input-group-append">
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary btn-number"
                                  data-type="plus"
                                  data-field="quant[1]"
                                  onClick={incrementCount}
                                >
                                  <span className="fa fa-plus"></span>
                                </button>
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <p>Mint with</p>
                            <select defaultValue={0} onChange={(e) => updatePayType(e.target.value)}>
                              <option value={0}>BNB</option>
                              <option value={1}>Half BNB & Token</option>
                            </select>
                          </div>
                          <div className="col-sm-3">
                            <p>BNB:</p>
                            <input
                              disabled
                              type="text"
                              name="quant[1]"
                              className="form-control input-number text-black"
                              value={priceBNB}
                              min="1"
                            />
                          </div>
                          <div className="col-sm-3">
                            <p>$CRC:</p>
                            <input
                              disabled
                              type="text"
                              name="quant[1]"
                              className="form-control input-number text-black"
                              value={priceTOKEN}
                              min="1"
                            />
                          </div>
                        </div>
                      </div>
                      <button onClick={mintNft} className="cmn-btn text-capitalize mt-4 d-flex justify-content-center mint-button">
                        Mint
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Backdrop open={loading}>
        <div className="flex justify-center m-auto flex-col">
          <p className="text-white font-bold w-2/3 m-auto text-center">
            Loading
          </p>
          <CircularProgress className="m-auto text-white" color="inherit" />
        </div>
      </Backdrop>
    </div>
  );
};

export default MintNft;
