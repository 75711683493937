import { useState, useEffect } from "react";
import { creepy_game, token } from "../../context/abi";
import { useAuth } from "../../context/AuthContext";
import { toast } from "react-toastify"
import Slider from "react-slick"

const ListBuyTicket = () => {
  const { web3 } = useAuth()
  const [contract, setContract] = useState(null)
  const [loading, setLoading] = useState(false)
  const [roundId, setRoundId] = useState(0)
  const [tickets, setTickets] = useState([])
  const [lastResult, setLastResult] = useState(null)

  const [prize5, setPrize5] = useState(null)
  const [prize4, setPrize4] = useState(null)
  const [prize3, setPrize3] = useState(null)

  useEffect(() => {
    const creepy_game_ct = new web3.library.eth.Contract(creepy_game.abi, creepy_game.contract)
    setContract(creepy_game_ct)
  }, [web3])


  useEffect(() => {
    if (contract) {
      getMyTickets()
    }

  }, [contract])

  const getMyTickets = async () => {
    const roundidx = await contract.methods.currentRoundId().call()
    setRoundId(Number(roundidx))
    const gettickets = await contract.methods.getTickets().call()
    setTickets(gettickets.filter(x => x.owner == web3.account))
    const getResult = await contract.methods.roundInfo(Number(roundidx) - 1).call()
    console.log(getResult)
    setLastResult(getResult)
    const getprize5 = await contract.methods.prizes(Number(roundidx) - 1, 0).call()
    setPrize5(getprize5)
    const getprize4 = await contract.methods.prizes(Number(roundidx) - 1, 1).call()
    console.log(getprize4)
    setPrize4(getprize4)
    const getprize3 = await contract.methods.prizes(Number(roundidx) - 1, 2).call()
    setPrize3(getprize3)
  }

  const claimPrize = async () => {
    setLoading(true)
    try {
      const isWinner = await contract.methods.checkIsWinner(web3.account).call()
      console.log(isWinner)
      if (isWinner) {
        contract.methods.claimPrize().send({
          from: web3.account
        }).then(() => {
          setLoading(false)
          toast("Claim prize successfull, please check your wallet")
        }).catch(() => {
          setLoading(false)
          toast("Claim prize failed")
        })
      } else {
        setLoading(false)
        toast("You are not winner")
      }
    } catch (error) {
      setLoading(false)
      toast("Claim prize failed")
    }
  }


  return (
    <div className="inner-hero-section style--two">
      <div className="pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">

              <div className="user-action-card">
                <ul className="user-action-list">
                  <li className="active">
                    <a>
                      My Tickets
                    </a>
                  </li>
                  <li>
                    <a href="#">Help Center</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8 mt-lg-0 mt-4">
              <div className="upcoming-draw-wrapper">
                <h3 className="title">Current Round #{roundId}</h3>
                <Slider className="draw-ticket-slider" dots={false} slidesToShow={2} slidesToScroll={1} infinite={false} speed={700} arrows={false} responsive={[
                  {
                    breakpoint: 1200,
                    settings: {
                      slidesToShow: 2
                    }
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 1
                    }
                  }
                ]}>
                  {
                    tickets.filter(x => x.roundId == roundId).map(val => (
                      <div className="draw-single-ticket">
                        <div className="draw-single-ticket__header">
                          <div className="left">Ticket #{val.ticketId}</div>
                          <div className="right">Round #{val.roundId}</div>
                        </div>
                        <div className="circle-divider">
                          <img
                            src="assets/images/elements/circle-border.png"
                            alt="image"
                          />
                        </div>
                        <ul className="ticket-numbers-list">
                          {
                            val.num5.padStart(5, "0").split("").map(x => <li>{x}</li>)
                          }
                        </ul>
                      </div>
                    ))
                  }
                </Slider>
              </div>
              {lastResult && <div className="past-draw-wrapper">
                <h3 className="title">Winner Number</h3>
                <div className="table-responsive-lg">
                  <table>
                    <thead>
                      <tr>
                        <th>Round</th>
                        <th>Number</th>
                        <th>Prize</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="contest-no">MEGA</span>
                        </td>
                        <td>
                          <ul className="number-list">
                            {lastResult.num5.padStart(5, "0").split("").map(x => <li>{x}</li>)}
                          </ul>
                        </td>
                        <td>
                          <span className="contest-no">{prize5 && web3.library.utils.fromWei(prize5.BNB)} BNB</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="contest-no">2nd</span>
                        </td>
                        <td>
                          <ul className="number-list">
                            {lastResult.num4.padStart(4, "0").split("").map(x => <li>{x}</li>)}
                          </ul>
                        </td>
                        <td>
                          <span className="contest-no">{prize4 && web3.library.utils.fromWei(prize4.BNB)} BNB</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="contest-no">3rd</span>
                        </td>
                        <td>
                          <ul className="number-list">
                            {lastResult.num3.padStart(3, "0").split("").map(x => <li>{x}</li>)}
                          </ul>
                        </td>
                        <td>
                          <span className="contest-no">{prize3 && web3.library.utils.fromWei(prize3.BNB)} BNB</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>}
              <div className="past-draw-wrapper">
                <h3 className="title">Last Draws</h3>
                <div className="table-responsive-lg">
                  <table>
                    <thead>
                      <tr>
                        <th>Round</th>
                        <th>Number</th>
                        <th>Result</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        tickets.filter(x => x.roundId == roundId - 1).map(val => (
                          <tr>
                            <td>
                              <span className="contest-no">#{val.roundId}</span>
                            </td>
                            <td>
                              <ul className="number-list">
                                {val.num5.padStart(5, "0").split("").map(x => <li>{x}</li>)}
                              </ul>
                            </td>
                            <td>
                              {lastResult && ((lastResult.num5 == val.num5 || lastResult.num4 == val.num4 || lastResult.num3 == val.num3) ?
                                <a className="cmn-btn style--three btn--sm" onClick={claimPrize}>
                                  <img
                                    src="assets/images/icon/btn/tag.png"
                                    alt="icon"
                                    className="mr-2"
                                  />{" "}
                                  Claim
                                </a> : <span className="fail">No Win</span>)}
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListBuyTicket;
