import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { creep_nft, token } from "../../context/abi";
import { useAuth } from "../../context/AuthContext";
import "./nftStaking.css";

const NftStaking = () => {
  const { web3 } = useAuth()
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState(null);
  const [contractToken, setContractToken] = useState(null);

  const [myNFT, setMyNFT] = useState([])
  const [current, setCurrent] = useState(null)

  useEffect(() => {
    const creepy_nft_ct = new web3.library.eth.Contract(
      creep_nft.abi,
      creep_nft.contract
    );
    setContract(creepy_nft_ct);
    setContractToken(new web3.library.eth.Contract(token.abi, token.contract));
  }, [web3]);

  useEffect(() => {
    if (contract) {
      loadData()
    }
  }, [contract, loading])

  const getTier = (tier) => {
    switch (tier) {
      case "0":
        return "S"
      case "1":
        return "A"
      case "2":
        return "B"
      case "3":
        return "C"
      case "4":
        return "D"
      case "5":
        return "E"
      default:
        return "E";
    }
  }

  const loadData = async () => {
    const getnfts = await contract.methods.nftTemplateOfOwner(web3.account).call()
    // console.log(getnfts)
    setMyNFT(getnfts)
    if (current) {
      setCurrent(getnfts.find((val) => val.tokenid === current.tokenid))
    } else {
      setCurrent(getnfts.find((val) => val.staking))
    }
    // setCurrent(null)
    // console.log(getnfts[0])
    // setCurrent(getnfts[0])
  }

  const startStaking = async (tokenId) => {
    setLoading(true)
    contract.methods.startStaking(tokenId).send({
      from: web3.account
    }).then(() => {
      setLoading(false)
      toast("Started staking")
    }).catch(() => {
      setLoading(false)
      toast("Started staking failed")
    })
  }

  const claimStaking = async (tokenId) => {
    setLoading(true)
    contract.methods.claimStaking(tokenId).send({
      from: web3.account
    }).then(() => {
      setLoading(false)
      toast("Started staking")
    }).catch(() => {
      setLoading(false)
      toast("Started staking failed")
    })
  }

  const stopStaking = async (tokenId) => {
    setLoading(true)
    contract.methods.stopStaking(tokenId).send({
      from: web3.account
    }).then(() => {
      setLoading(false)
      toast("Started staking")
    }).catch(() => {
      setLoading(false)
      toast("Started staking failed")
    })
  }

  return (
    <div className="inner-hero-section style--two">
      <div className="container">
        <section className="pb-120">
          <div className="container">
            {current && <div className="row justify-content-center">

              <div className="col-lg-12">
                <div className="contest-cart">
                  <div className="contest-cart__left">
                    <div className="contest-cart__slider-area">
                      <div className="contest-cart__thumb-slider">
                        <div className="single-slide">
                          <img src="assets/images/contest/b1.png" alt="image" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="contest-cart__right pb-120">
                    <h4 className="subtitle">NFT Staking #{current.tokenid}</h4>
                    <div className="staking-content__container">
                      <div className="staking-content__title">
                        <img
                          className="mr-2 mb-2"
                          src="/assets/images/minepower.svg"
                          alt="image"
                          width={24}
                          height={24}
                        />
                        <p className="blog-card__title">{current.hashrate}</p>
                      </div>
                      <p className="mt-minus-10">Staking Power</p>
                    </div>
                    <div className="staking-content__container">
                      <div className="staking-content__title">
                        <img
                          className="mr-2 mb-2 text-white"
                          src="/assets/images/time.svg"
                          alt="image"
                          width={24}
                          height={24}
                        />
                        <p className="blog-card__title">{new Date(Number(current.lastClaim) * 1000).toLocaleDateString()}</p>

                      </div>
                      <p className="mt-minus-10">Last time claim</p>
                    </div>

                    <div className="staking-content__container">
                      <div className="staking-content__title">
                        <img
                          className="mr-2 mb-2"
                          src="/assets/images/minepower.svg"
                          alt="image"
                          width={24}
                          height={24}
                        />
                        <p className="blog-card__title">{current.claimCount}</p>
                      </div>
                      <p className="mt-minus-10">Claim count</p>
                    </div>

                    {/* <div className="progressbar"></div> */}
                    <div className="staking-bottom d-flex flex-wrap align-items-center">
                      <div>
                        <div className="staking-content__container">
                          <div className="staking-content__title">
                            <p className="blog-card__title">
                              {
                                web3 && parseFloat(current.staking ? web3.library.utils.fromWei(((parseInt(Date.now() / 1000) - Number(current.lastClaim)) * Number(current.hashrate)).toString()) : 0).toFixed(10)
                              } BNB
                            </p>
                          </div>
                          <p className="mt-minus-10">Next claim</p>
                        </div>
                      </div>
                      {
                        current.staking ?
                          <div className="mt-sm-0 mt-3 mr-5">
                            <button className="cmn-btn style--three m-2" onClick={() => stopStaking(Number(current.tokenid))}>
                              Stop
                            </button>
                            <button className="cmn-btn style--three m-2" onClick={() => claimStaking(Number(current.tokenid))}>
                              Claim
                            </button>
                          </div> :
                          <div className="mt-sm-0 mt-3 mr-5">
                            <button className="cmn-btn style--three m-2" onClick={() => startStaking(Number(current.tokenid))}>
                              Staking
                            </button>
                          </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>}

            <div className="row mb-none-30 mt-50">
              {
                myNFT.map((val, index) => (
                  <div key={`NFT-${index}`} className="col-xl-3 col-sm-6 mb-30" onClick={() => setCurrent(val)}>
                    <div className="affiliate-card nft-container">
                      <div className="nft-container__img">
                        <img src="assets/images/nft.jpeg" alt="image" />
                        <div className="nft-container__top">

                          <div className="nft-container__id px-2">
                            <p>Tier {getTier(val.tier)}</p>
                          </div>
                        </div>
                      </div>

                      <h4 className="mt-10">#{val.tokenid}</h4>
                      <hr className="underline" />
                      <div className="d-flex flex-wrap align-items-center nft-text__container">
                        <p className="nft-text__left">Staking power</p>
                        <p className="nft-text__right">{val.hashrate}</p>
                      </div>
                      {/* <h4>527.000 BUSD</h4> */}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </div>
      <Backdrop open={loading}>
        <div className="flex justify-center m-auto flex-col">
          <p className="text-white font-bold w-2/3 m-auto text-center">
            Loading
          </p>
          <CircularProgress className="m-auto text-white" color="inherit" />
        </div>
      </Backdrop>
    </div>
  );
};

export default NftStaking;
