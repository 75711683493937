import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const Header = () => {
  const { web3, setWeb3type } = useAuth();

  return (
    <header className="header">
      <div className="header__top">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <div className="left d-flex align-items-center text-white">
                <a>
                  <i className="las la-phone-volume"></i> Support
                </a>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="right">
                <div className="product__cart">
                  {web3 && web3.active && (
                    <span
                      className="total__amount text-truncate"
                      style={{
                        maxWidth: "200px",
                      }}
                    >
                      {web3.account}
                    </span>
                  )}
                </div>
                {web3 && web3.active && (
                  <button
                    className="user__btn"
                    onClick={() => {
                      setWeb3type(null);
                      localStorage.removeItem("web3type");
                      web3.deactivate();
                    }}
                  >
                    <i className="las la-arrow-circle-right"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header__bottom">
        <div className="container">
          <nav className="navbar navbar-expand-xl p-0 align-items-center">
            <Link to={"/"}>
              <a className="site-logo site-title">
                <img src="/assets/images/logo.png" width={200} alt="site-logo" />
                <span className="logo-icon">
                  <i className="flaticon-fire"></i>
                </span>
              </a>
            </Link>
            <button
              className="navbar-toggler ml-auto"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="menu-toggle"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav main-menu ml-auto">
                <li>
                  <Link to={"/"}>Home</Link>
                </li>
                <li className="menu_has_children">
                  <a>NFT</a>
                  <ul className="sub-menu">
                    <li>
                      <Link className="link-default" to={"/mint"}>
                        <a>Mint NFT</a>
                      </Link>
                    </li>
                    <li>
                      <Link className="link-default" to={"/staking"}>
                        <a>Staking NFT</a>
                      </Link>
                    </li>
                    <li>
                      <a>Marketplace</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to={"/mytickets"}>My tickets</Link>
                </li>
                <li>
                  <a>Documents</a>
                </li>
                <li>
                  <a>Buy $CRC</a>
                </li>
              </ul>
              <div className="nav-right">
                <Link to={"/buyticket"}>
                  <a className="cmn-btn style--three btn--sm">
                    <img
                      src="assets/images/icon/btn/tag.png"
                      alt="icon"
                      className="mr-2"
                    />{" "}
                    Buy Tickets
                  </a>
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
