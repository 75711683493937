

const Footer = () => {

    return (
        <footer className="footer-section">
            <div className="bg-shape--top">
                {/* <img src="assets/images/elements/round-shape-2.png" alt="image" /> */}
            </div>
            <div className="container pt-120">
                <div className="row pb-5 align-items-center">
                    <div className="col-lg-4">
                        <ul className="app-btn">
                            <li><a href="#"><img src="assets/images/icon/store-btn/1.png" alt="image" /></a></li>
                            <li><a href="#"><img src="assets/images/icon/store-btn/2.png" alt="image" /></a></li>
                        </ul>
                    </div>
                    <div className="col-lg-8">
                        <ul className="short-links justify-content-lg-end justify-content-center">
                            <li><a href="#">Abount</a></li>
                            <li><a href="#">FAQs</a></li>
                            <li><a href="#">Contact</a></li>
                            <li><a href="#">Terms of Services</a></li>
                            <li><a href="#">Privacy</a></li>
                        </ul>
                    </div>
                </div>
                <hr />
                <div className="row py-5 align-items-center">
                    <div className="col-lg-6">
                        <p className="copy-right-text text-lg-left text-center mb-lg-0 mb-3">Copyright © 2022. All Rights Reserved</p>
                    </div>
                    <div className="col-lg-6">
                        <ul className="social-links justify-content-lg-end justify-content-center">
                            <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                            <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer